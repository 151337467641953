import { render, staticRenderFns } from "./EntityCloneModal.vue?vue&type=template&id=5c636528&scoped=true&"
import script from "./EntityCloneModal.vue?vue&type=script&lang=js&"
export * from "./EntityCloneModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c636528",
  null
  
)

export default component.exports