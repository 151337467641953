<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-8">
      <router-link
        :to="{ name: 'ProjectEntities' }"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      Terug naar overzicht
    </h2>
    <template v-if="!importSuccessful">
      <div v-if="validationText" v-html="validationText" class="tw-font-bold tw-text-red-500" />
      <form v-if="!importing" @submit.prevent="submit">
        <input
          ref="fileInput"
          type="file"
          accept="text/csv"
          required
          class="tw-block tw-w-full tw-mt-4 tw-my-8"
          @input="validationText = ''"
        />
        <input type="submit" value="Importeren starten" class="formulate-button" :disabled="!!validationText" />
      </form>
    </template>
    <div v-if="importing">
      <i class="fa fa-spinner fa-spin tw-mr-2" />
      <span>Importeren ...</span>
    </div>
    <div v-if="importSuccessful">
      <div class="tw-p-4 tw-mb-4 tw-text-sm tw-text-green-700 tw-bg-green-100 tw-rounded tw-full md:tw-w-1/4" role="alert">
        <span class="tw-font-bold">Het importeren van de entiteiten is geslaagd.</span>
      </div>
      <button
        type="button"
        title="Nieuwe import"
        class="btn btn-default"
        @click="importSuccessful = false"
      >
        <i class="fa fa-repeat tw-mr-1" /> Nieuwe import
      </button>
    </div>
  </div>
</template>

<script>
import { questionModal } from '@/modalMessages'
import { getImportEntitiesJobStatus, importEntitiesStart, importEntitiesConfirm } from '@/services/projects'
import { poll } from '@/utils/helpers'
export default {
  data () {
    return {
      validationText: '',
      importing: false,
      importSuccessful: false
    }
  },
  computed: {
    projectId () {
      return this.$route.params.id
    }
  },
  methods: {
    async submit () {
      try {
        const formData = new FormData()
        formData.append('file', this.$refs.fileInput.files[0])
        const response = await importEntitiesStart(this.projectId, formData)

        const message = `
          Totaal aantal entiteiten: ${response?.data?.count_total}
          <br>
          Nieuw: ${response?.data?.count_new}
          <br>
          Update: ${response?.data?.count_update}
        `
        const result = await questionModal(message, 'Bevestig de import')
        if (!result.value) return response

        this.importing = true
        const confirmResponse = await importEntitiesConfirm(this.projectId, formData)
        await poll(confirmResponse?.data?.job_id, getImportEntitiesJobStatus, 1000)
        this.importSuccessful = true
        this.importing = false

        return response
      } catch (error) {
        this.importing = false
        const responseCode = error.response?.data?.code
        // Show whatever the back-end returns as error (e.g. validations) as it might be helpful for the user to fix the values filled out in the CSV.
        const defaultErrorMessageHtml = `
          Er ging iets mis bij het importeren van de data. 
          Gelieve het CSV-bestand op de juistheid ervan te controleren.
          <br><span class="tw-text-xs tw-italic">${JSON.stringify(error?.response?.data)}</span>
        `
        const mapping = {
          NUMBER_OF_COLUMNS_DOES_NOT_MATCH: 'Het bestand bevat een verkeerd aantal kolommen. Gelieve de template te gebruiken zoals aangeleverd.',
          PROJECT_REFERENCE_DOES_NOT_MATCH: 'Eén van de projectreferenties in de eerste kolom komt niet overeen met de referentie van het project in IAM.',
          ENTITY_REFERENCE_NOT_UNIQUE: 'Eén van de referenties van de entiteiten is niet uniek.'
        }
        this.validationText = mapping[responseCode] || defaultErrorMessageHtml
      }
    }
  }
}
</script>
