<template>
  <div>
    <PropertyCreateModal
      ref="newPropertyModal"
      :redirect="false"
      :project="project"
      @success="loadProjectEntities"
    />
    <EntityCloneModal
      ref="entityCloneModal"
      :project="project"
      @cloned="loadProjectEntities"
    />
    <PanelBox title="Entiteiten">
      <template slot="toolbar">
        <a @click="addEntity"
           class="btn btn-primary btn-xs"
           type="button"
        >
          <i class="fa fa-plus" /> Pand toevoegen
        </a>
        <router-link :to="{ name: 'ProjectEntitiesImport' }" class="btn btn-primary btn-xs">
          <i class="fa fa-upload" /> Importeren
        </router-link>
      </template>
      <DataGrid
        :data="projectEntities"
        :columns="columns"
        :pageSize="10"
        :searchFields="searchFields"
        :actions="actions"
        @unlink="unlinkEntity"
        @clone="cloneEntity"
      />
    </PanelBox>
  </div>
</template>

<script>
import Vue from 'vue'
import PanelBox from '@/components/iam/PanelBox'
import DataGrid from '@/components/iam/DataGrid'
import PropertyCreateModal from '@/components/properties/PropertyCreateModal'
import EntityCloneModal from '@/components/properties/EntityCloneModal'
import ReferenceHoverable from '@/components/iam/table_components/ReferenceHoverable'
import { mapGetters } from 'vuex'
import { getProperties, updateProperty } from '@/services/properties'

Vue.component('entity-published-display', {
  props: ['value'],
  template: '<span>{{ value|yesno }}</span>'
})

Vue.component('entity-price-display', {
  props: ['value'],
  template: '<span v-if="value">&euro; {{ value|currency}}</span><span v-else>Niet ingevuld</span>'
})

export default {
  name: 'ProjectEntities',
  components: {
    PanelBox,
    DataGrid,
    EntityCloneModal,
    PropertyCreateModal
  },
  data () {
    return {
      id: 'CommunicationDetail',
      entity: null,
      columns: [
        { key: '', title: 'Referentie', renderComponent: ReferenceHoverable },
        { key: 'unit_no', title: 'Unit' },
        { key: 'fase_display', title: 'Fase' },
        { key: 'status_display', title: 'Status' },
        { key: 'type_display', title: 'Type' },
        { key: 'published', title: 'Gepubliceerd', renderComponent: 'entity-published-display' },
        { key: 'price', title: 'Prijs', renderComponent: 'entity-price-display' },
        { key: 'surface_plot', title: 'Perceeloppervlakte' },
        { key: 'surface_livable', title: 'Bruto bewoonbare oppervlakte' },
        { key: 'surface_trading', title: 'Bruto handelsoppervlakte' }
      ],
      actions: [
        {
          key: 'unlink',
          type: 'danger',
          icon: 'unlink',
          title: 'Entiteit loskoppelen van project'
        },
        {
          key: 'clone',
          type: 'success',
          icon: 'copy',
          title: 'Entiteit dupliceren'
        }
      ],
      enableSearch: true,
      searchFields: ['reference'],
      selectedEntity: null,
      projectEntities: []
    }
  },
  computed: {
    ...mapGetters('properties', ['getProjectById']),

    projectId () {
      return this.$route.params.id
    },
    project () {
      return this.getProjectById(this.projectId)
    }
  },
  created () {
    this.loadProjectEntities()
  },
  methods: {
    addEntity () {
      this.$refs.newPropertyModal.show()
    },
    cloneEntity (entity) {
      this.$refs.entityCloneModal.show(entity)
    },
    async loadProjectEntities () {
      const payload = { params: { project: this.projectId, include_archived: true } }
      const response = await getProperties(payload)
      this.projectEntities = response.data?.results.map(entity => {
        return {
          ...entity,
          type: 'property'
        }
      })
      return response
    },
    async unlinkEntity (entity) {
      const response = await updateProperty(entity.id, { project: null })
      await this.loadProjectEntities()
      return response
    }
  }
}
</script>
