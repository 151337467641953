<template>
  <div>
    <Modal ref="modal" title="Entiteit dupliceren" @hide="cloneCount=1">
      <template v-if="property && project">
        <div class="form-group" style="height:30px;">
          <label class="col-sm-3" for="clone-count"> Aantal duplicaten: </label>
          <div class="col-sm-2">
            <input
              class="form-control"
              type="number"
              min="1"
              max="100"
              v-model="cloneCount"
              id="clone-count">
          </div>
        </div>

        <div class="form-group" style="height:30px;">
          <label class="col-sm-3" for="base-reference"> Basisreferentie: </label>
          <div class="col-sm-4">
            <input
              class="form-control"
              type="text"
              v-model="baseReference"
              id="base-reference">
          </div>
        </div>

        <div class="form-group" style="height:30px;">
          <label class="col-sm-3" for="reference-number"> Volgnummer: </label>
          <div class="col-sm-2">
            <input
              class="form-control"
              type="number"
              min="0"
              v-model="referenceNumber"
              id="reference-number">
          </div>
        </div>

        <div class="panel panel-info">
          <div class="panel-heading">
            <i class="fa fa-info-circle"></i> Info Panel
          </div>
          <div class="panel-body">
            Volgende entiteiten worden aangemaakt: <br><br>
            <ul>
              <li v-for="(reference, index) in cloneReferences" :key="index">{{reference}}</li>
            </ul>
          </div>
        </div>

        <div slot="footer">
          <a type="button" class="btn btn-success" @click="createClones" target="_blank">Aanmaken</a>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/iam/Modal'
import { successModal, startLoadingModal, warningModal } from '@/modalMessages.js'
import { duplicateProperty } from '@/services/properties'

export default {
  name: 'EntityCloneModal',
  components: {
    Modal
  },
  data () {
    return {
      cloneCount: 1,
      baseReference: null,
      referenceNumber: 1,
      property: null
    }
  },
  props: {
    project: Object
  },
  watch: {
    cloneCount () {
      if (this.cloneCount > 100) {
        this.cloneCount = 100
      }
      if (this.cloneCount < 1) {
        this.cloneCount = 1
      }
    },
    referenceNumber () {
      const referenceNumber = parseInt(this.referenceNumber)
      if (Number.isNaN(referenceNumber)) {
        this.referenceNumber = 1
      }
      if (referenceNumber < 1) {
        this.referenceNumber = 1
      }
    }
  },
  computed: {
    /** The references for the new clones */
    cloneReferences () {
      const references = []
      let referenceNumber = parseInt(this.referenceNumber)
      while (references.length < this.cloneCount) {
        const reference = `${this.baseReference}${referenceNumber}`
        references.push(reference)
        referenceNumber += 1
      }
      return references
    }
  },
  methods: {
    show (entity) {
      this.property = entity
      this.initializeCloneFields()
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },

    /** Suggests the initial field values */
    initializeCloneFields () {
      const originalReference = this.property.reference
      const reference = originalReference.split('-')
      if (reference.length > 1) {
        try {
          this.referenceNumber = parseInt(reference.pop()) + 1
        } catch (e) {
          this.referenceNumber = 1
        }
      } else {
        this.referenceNumber = 1
      }
      this.baseReference = reference.join('-')
    },

    /** Creates the clones */
    async createClones () {
      startLoadingModal('Bezig met panden aanmaken...')
      const failedClones = []
      for (const cloneReference of this.cloneReferences) {
        try {
          await duplicateProperty(
            this.property.id,
            {
              reference: cloneReference,
              keep_owners: true,
              keep_renters: true,
              keep_notaries: true,
              keep_pictures: true
            }
          )
        } catch (e) {
          failedClones.push(cloneReference)
          console.error(e)
        }
      }
      this.$emit('cloned')

      if (failedClones.length === 0) {
        successModal('Pand is successvol gekopieerd')
      } else {
        warningModal(`Panden met volgende referenties: ${failedClones.join(', ')} zijn niet aangemaakt omdat hun referentie reeds bestaat`)
      }
    }
  }
}
</script>

<style scoped>

</style>
